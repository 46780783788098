<template>
  <el-row class="movie-list">
    <el-col :md="18">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row>
              <span v-html="article.title" />
            </el-row>
            <el-divider/>
            <el-row>
              <el-col :md="2">
                <router-link target="_blank" :to="`/user/` + userAvatar.userId">
                  <el-avatar>
                    <el-image :src="userAvatar.avatarUrl" />
                  </el-avatar>
                </router-link>
              </el-col>
              <el-col :md="10">
                <el-row>
                  <span v-html="userAvatar.screenName"></span>
                </el-row>
                <el-row>
                  <span>-</span>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <div class="text item">
            <el-row>
              <span v-html="article.content" />
            </el-row>
            <el-divider/>
            <el-row>
              发布于 <span v-html="article.createAt" />
            </el-row>
            <el-divider/>
            <el-row>
              <span>
                <span>
                  <i :class=collectedIcon @click="collectItem"/>
                </span>
              </span>
            </el-row>
          </div>
        </el-card>
      </el-row>
    </el-col>
    <el-col :md="6">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-row>
              <span>推荐文章</span>
            </el-row>
          </div>
          <div class="text item">
            <el-row>
            </el-row>
          </div>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { getArticle } from "@/api/article";
import {getUserInfo} from "@/api/user";

export default {
  name: 'ArticlePage',
  components: {},
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 20
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  data() {
    return {
      article: null,
      userAvatar: null,
      collected: false,
      collectedIcon: 'el-icon-star-off'
    }
  },
  created() {
    const articleId = this.$route.params.articleId
    getArticle(articleId).then(resp => {
      if (resp.code === 0) {
        this.article = resp.data
        document.title = '文章 - ' + this.article.title

        getUserInfo(this.article.userId).then(resp => {
          if (resp.code === 0) {
            this.userAvatar = resp.data
          }
        })
      } else {
      }
    })
  },
  methods: {
    collectItem() {
      this.$message.info('暂未实现')
      if (this.collected) {
        console.log('取消收藏')
        this.collected = false
        this.collectedIcon = 'el-icon-star-off'
      } else {
        console.log('收藏')
        this.collected = true
        this.collectedIcon = 'el-icon-star-on'
      }
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
